import clsx from 'clsx'
import { ShouldRender } from '../misc'
import Pill from '@/components/shared/Pill'
import { filter, isEmpty, isEqual, slice } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CheckIcon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Select, Tag } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

type Props = {
  className?: string
  data: OptionData[]
  defaultSelection?: OptionData[]
  disabled?: boolean
  maxLength?: number
  mode?: 'multiple' | 'tags'
  maxDropdownPills?: number
  onSelectionChange: (data: OptionData[]) => void
  placeholder?: string
  theme?: 'PRIMARY' | 'SECONDARY'
  showTags?: boolean
  showPills?: boolean
  size?: string
  includeCustomValue?: boolean
  showSearch?: boolean
  handleSearchQuery?: (data) => void
  loading?: boolean
  pillsClassName?: {
    pillsBackgroundColor?: string
    pillsTextColor?: string
    theme?: string
  }
  inferredValues?: string[]
  allowReset?: boolean
}

export type OptionData = {
  _id?: string
  value?: string
  label?: string
}

const MultiSelectV3: React.FC<Props> = ({
  className,
  data,
  defaultSelection = [],
  disabled = false,
  maxLength = 10,
  maxDropdownPills = 3,
  mode,
  onSelectionChange,
  showTags = false,
  placeholder,
  theme = 'PRIMARY',
  showPills = true,
  size = 'medium',
  includeCustomValue = false,
  handleSearchQuery,
  loading = false,
  showSearch,
  pillsClassName,
  inferredValues = [],
  allowReset = false
}) => {
  const [selected, setSelected] = useState<string[]>([])
  const ref = useRef(true)
  const [query, setQuery] = useState<string>('')
  const bgColor =
    pillsClassName?.pillsBackgroundColor ??
    (theme === 'PRIMARY' ? 'bg-qureosPrimary' : 'bg-gray-100')
  const textColor =
    pillsClassName?.pillsTextColor ??
    (theme === 'PRIMARY' ? 'text-white' : 'text-gray-800')

  const formattedData = useMemo(() => {
    const formattedVal = data?.map(val => ({
      value: val.label
    }))
    if (includeCustomValue) {
      formattedVal.push({ value: query })
    }
    return formattedVal
  }, [data, includeCustomValue, query])

  useEffect(() => {
    if (ref.current) {
      ref.current = false
      return
    }
    const selectedList = filter(data, obj => selected?.includes(obj.label))

    if (includeCustomValue) {
      selected?.forEach(str => {
        if (!data.some(obj => obj.label === str)) {
          selectedList.push({ value: str, label: str })
        }
      })
    }

    onSelectionChange(selectedList)
  }, [JSON.stringify(selected)])

  useEffect(() => {
    if (defaultSelection?.length > 0) {
      setSelected(defaultSelection.map(v => v.label))
    } else {
      setSelected([])
    }
  }, [defaultSelection])

  const handleRemoval = (label: string) => {
    const result = selected?.filter(
      val => !isEqual(val.toLowerCase(), label.toLowerCase())
    )
    setSelected(result)
  }

  const renderTag = (props: CustomTagProps) => {
    const { label, closable, onClose } = props
    const showIcon = inferredValues?.some(v => isEqual(label, v))

    return (
      <ShouldRender check={showTags && selected?.length}>
        <div className={clsx('flex gap-2 flex-wrap')}>
          <Tag
            closable={closable}
            onClose={onClose}
            className={`${bgColor} ${textColor} inline-flex items-center rounded-3xl border-0`}
          >
            {showIcon && (
              <SparklesIcon
                fill={'#0E9F6E'}
                className="w-4 h-4 scale-x-[-1] mr-1"
              />
            )}
            {label}
          </Tag>
        </div>
      </ShouldRender>
    )
  }

  const renderCount = (selectedValues: string[]) => {
    const extraCount = (selectedValues?.length || 0) - maxDropdownPills
    return (
      <Tag
        className={`${bgColor} ${textColor} inline-flex items-center rounded-3xl border-0`}
      >
        {`+ ${extraCount}`}
      </Tag>
    )
  }

  return (
    <React.Fragment>
      <Select
        showSearch
        showArrow
        allowClear={allowReset}
        maxTagCount={maxDropdownPills}
        loading={loading}
        mode={mode}
        value={selected}
        disabled={disabled}
        tagRender={renderTag}
        maxTagTextLength={15}
        menuItemSelectedIcon={
          <CheckIcon className="h-5 w-5" fill={'#0E9F6E'} aria-hidden="true" />
        }
        options={formattedData}
        placeholder={placeholder}
        onSearch={str => {
          setQuery(str)
          if (handleSearchQuery) {
            handleSearchQuery(str)
          }
        }}
        className={clsx('w-full max-w-[435px] mt-2', className)}
        maxTagPlaceholder={renderCount(selected)}
        onChange={(val: string[]) => {
          if (val?.length <= maxLength || isEmpty(val)) setSelected(val)
        }}
      />
      <ShouldRender check={showPills && selected?.length}>
        <div className="mt-4 flex gap-2 flex-wrap">
          {slice(selected, 0, maxDropdownPills).map(val => {
            return (
              <Pill
                key={val}
                size={size}
                textColorClass={textColor}
                backgroundColorClass={bgColor}
                showIcon={inferredValues.some(v => isEqual(val, v))}
                text={val}
              >
                <XMarkIcon
                  onClick={() => !disabled && handleRemoval(val)}
                  className={clsx(
                    size === 'small' ? 'h-3 w-3' : 'h-4 w-4',
                    !disabled && 'cursor-pointer',
                    textColor
                  )}
                />
              </Pill>
            )
          })}
        </div>
      </ShouldRender>
    </React.Fragment>
  )
}

export default MultiSelectV3
