import { LearningHubTabContext } from '@/providers/learning-hub/LearningHubTabProvider'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { Fragment, ReactNode, useContext, useEffect } from 'react'

export interface ITab {
  /**
   * Title for the navigation element
   */
  title: string
  /**
   * Component that needs to be rendered on the tab
   */
  children: JSX.Element

  /**
   * Unique identifier for each tab
   */
  key: string
}
interface ITabNavigation {
  tabs: ITab[]

  /**
   * Classname for the top level wrapper
   */
  className?: string
  isPreserve?: boolean
  showTabs?: boolean
}

const TabNavigation: React.FC<ITabNavigation> = ({
  tabs,
  className,
  isPreserve = false,
  showTabs = true
}) => {
  const { activeTab, setActiveTab } = useContext(LearningHubTabContext)
  const router = useRouter()
  const { currentTab } = router.query
  const screen = useBreakpoint()

  const tabComponent = tabs.find(tab => tab.key === activeTab)

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab as string)
    }
  }, [currentTab])

  const desktopTabs = (
    <div className="flex gap-10 m-3 mb-0">
      {tabs.map((tab, indx) => {
        return (
          <p
            className={clsx('pb-4 font-medium cursor-pointer', {
              'border-b-2 border-qureosPrimary text-qureosPrimary':
                activeTab === tab.key,
              'text-gray-500': tab.key !== activeTab
            })}
            key={indx}
            onClick={() => {
              setActiveTab(tab.key)
              if (isPreserve) {
                router.pathname = router.asPath.split('?')?.[0]
                router.query = {
                  ...router.query,
                  currentTab: String(tab.key)
                }
                router.push(router)
              }
            }}
          >
            {tab.title}
          </p>
        )
      })}
    </div>
  )

  const mobileTabs = (
    <Menu
      as="div"
      className="mobile-tab-selector relative inline-block text-left w-full z-10 p-4 pb-0"
    >
      <div>
        <Menu.Button className="inline-flex w-full bg-white border border-gray-300 rounded-md font-normal px-4 py-2 text-sm text-gray-500 ">
          {tabComponent?.title}
          <ChevronDownIcon
            className="ml-auto h-5 w-5 text-gray-500 hover:text-gray-600"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            {tabs.map(tab => {
              return (
                <Menu.Item key={tab.key}>
                  <button
                    onClick={() => setActiveTab(tab.key)}
                    className={`${
                      activeTab === tab.key
                        ? ' text-qureosPrimary'
                        : 'text-gray-600'
                    } group flex w-full items-center rounded-md font-normal text-sm px-2 py-2 bg-white`}
                  >
                    {tab.title}
                  </button>
                </Menu.Item>
              )
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )

  return (
    <div className={clsx('overflow-hidden', className)}>
      <qc-should-render check={showTabs}>
        {screen.sm ? desktopTabs : mobileTabs}
      </qc-should-render>
      <div className="content-tab">{tabComponent?.children}</div>
    </div>
  )
}

export default TabNavigation
