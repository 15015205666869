import { SparklesIcon } from '@heroicons/react/24/solid'
import { Popover } from 'antd'
import clsx from 'clsx'
import { ReactNode } from 'react'

type Props = {
  text: string | ReactNode
  showIcon?: boolean
  backgroundColorClass?: string
  textColorClass?: string
  iconColorClass?: string
  size?: string
  isApprentice?: boolean
  showTooltip?: boolean
  tooltipText?: string
  children?: ReactNode
  width?: string
}

const Pill: React.FC<Props> = ({
  text,
  showIcon = false,
  textColorClass,
  backgroundColorClass,
  iconColorClass,
  children,
  size = 'default',
  isApprentice = false,
  showTooltip = false,
  tooltipText = '',
  width
}) => {
  const sizeMap = {
    small: 'px-2 py-1 text-xs',
    medium: 'px-2.5 py-0.5 text-sm',
    default: 'px-4 py-2'
  }
  function renderPill() {
    return (
      <div
        className={clsx(
          sizeMap[size],
          width ? width : '',
          'px-2 py-1 rounded-3xl flex gap-1.5 items-center break-normal capitalize',
          backgroundColorClass ?? 'bg-[#2667FF1A]',
          textColorClass ?? '#d1dfff',
          {
            'cursor-pointer': showTooltip
          }
        )}
        style={{
          color: textColorClass,
          backgroundColor: backgroundColorClass
        }}
      >
        {showIcon && (
          <SparklesIcon
            fill={isApprentice ? '#3461ff' : iconColorClass || '#0E9F6E'}
            className="w-4 h-4 scale-x-[-1]"
          />
        )}
        {text}
        {children}
      </div>
    )
  }

  return (
    <>
      {!showTooltip ? (
        renderPill()
      ) : (
        <Popover content={<p>{tooltipText}</p>}>{renderPill()}</Popover>
      )}
    </>
  )
}

export default Pill
