import { Loading } from '@/components/shared/basic'
import { getAppliedInterviewStatus } from '@/utils/static-helpers/interviewStatusConversion'
import { Col, Row } from 'antd'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { getAppliedJobsByApprentice } from 'src/services/jobs'
import { JobCard } from '../JobCard'

const AppliedJobs: React.FC = () => {
  const [jobs, setAppliedJobs] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const jobs = await getAppliedJobsByApprentice()
        setAppliedJobs(jobs)
        setLoading(false)
      } catch {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full gap-4 p-4">
          {jobs?.length ? (
            <Row gutter={16} className="flex flex-wrap">
              {jobs?.map((item, index) => (
                <Col
                  key={`job-${index + 1}`}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  className="mb-4"
                >
                  <Link href={`/jobs/${item._id}?isApplied=true`}>
                    <a className="hover:text-black flex justify-center">
                      <JobCard
                        job={item}
                        width="w-full max-w-[470px]"
                        applicationStatus={getAppliedInterviewStatus(
                          item?.application?.aiInterviewStatus
                        )}
                      />
                    </a>
                  </Link>
                </Col>
              ))}
            </Row>
          ) : (
            <div>No jobs applied yet</div>
          )}
        </div>
      )}
    </>
  )
}

export default AppliedJobs
