import { AiInterviewStatus } from '@qureos/types'

export const getInterviewStatus = status => {
  switch (status) {
    case AiInterviewStatus.Invited:
    case AiInterviewStatus.Pending: {
      return <p className="capitalize text-red-500">Pending</p>
    }
    case AiInterviewStatus.InProgress: {
      return <p className="capitalize text-yellow-500">In Progress</p>
    }
    case AiInterviewStatus.InterviewTaken:
    case AiInterviewStatus.InterviewCompleted: {
      return <p className="capitalize text-green-500">Completed</p>
    }
    default:
      break
  }
}

export const getAppliedInterviewStatus = status => {
  switch (status) {
    case AiInterviewStatus.Invited:
    case AiInterviewStatus.Pending: {
      return <p className="capitalize text-red-500">Interview Pending</p>
    }
    default:
      break
  }
}
