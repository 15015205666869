import MultiSelectV3 from '@/components/shared/MultiSelectV3'
import { OptionData } from '@/components/shared/SelectBase'
import Modal from '@/components/shared/basic/Modal/ModalNew'
import { ShouldRender } from '@/components/shared/misc'
import { Label } from '@/components/shared/misc/Typography/Label'
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid'
import { Button as AntdButton } from 'antd'
import clsx from 'clsx'
import {
  debounce,
  first,
  isEmpty,
  isUndefined,
  map,
  toLower,
  uniqBy
} from 'lodash'
import { FC, Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getExistingJobTitles } from 'src/services/jobTitles'

enum FilterEnum {
  LOCATION = 'LOCATION',
  DEPARTMENT = 'DEPARTMENT',
  DATE_POSTED = 'DATE_POSTED',
  EASY_APPLY = 'EASY_APPLY',
  JOB_TITLE = 'JOB_TITLE'
}
interface Props {
  defaultValues?: {
    location?: OptionData[]
    departments?: OptionData[]
    datePosted?: OptionData[]
    easyApply?: boolean
    jobTitle?: OptionData[]
  }
  defaultOptions?: {
    locations?: OptionData[]
    departments?: OptionData[]
    datePosted?: OptionData[]
    jobTitles?: OptionData[]
  }
  hideFilters?: Array<keyof typeof FilterEnum>
  onChange?: (data: {
    location: string
    departments: string[]
    datePosted: string
    easyApply: boolean
    jobTitle: string
  }) => void
}

const commonLabelClass = 'text-lightGray font-medium text-sm block text-left'

const LabelComponent: FC<{ label: string }> = ({ label }) => (
  <div className="w-full flex items-start -mb-3">
    <Label
      data={{
        text: label,
        className: commonLabelClass
      }}
    />
  </div>
)

const JobFilterOrganism: FC<Props> = ({
  defaultValues,
  defaultOptions,
  hideFilters,
  onChange
}) => {
  // states for job title search
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [jobTitleOptions, setJobTitleOptions] = useState<OptionData[]>()
  const { t: Labels } = useTranslation('common')

  const selectedValues = useMemo(
    () => ({
      location: defaultValues.location[0]?.value,
      departments: map(
        defaultValues.departments,
        department => department.value
      ),
      datePosted:
        defaultValues.datePosted?.length > 0 &&
        defaultValues.datePosted[0]?.value,
      easyApply:
        defaultValues.easyApply !== true ? undefined : defaultValues.easyApply,
      jobTitle: first(defaultValues.jobTitle)?.value || undefined
    }),
    [defaultValues]
  )

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleJobTitleSearch = debounce(data => {
    if (data.length > 0) {
      onChange?.({
        ...selectedValues,
        jobTitle: undefined
      })
      setLoadingSearch(true)
      getExistingJobTitles({
        query: data,
        limit: 20
      })
        .then(result => {
          setJobTitleOptions(
            map(uniqBy(result, toLower), title => ({
              label: title,
              value: title
            }))
          )
        })
        .catch(() => {
          console.error('[jobTitleSearch] - error - no result found', {
            query: data
          })
          setJobTitleOptions([])
        })
        .finally(() => {
          setLoadingSearch(false)
        })
    }
  }, 300)

  const otherFilters = (showLabel?: boolean) => (
    <Fragment>
      {/* DEPARTMENT FILTER */}
      <ShouldRender check={showLabel}>
        <LabelComponent label={Labels('DEPARTMENT')} />
      </ShouldRender>
      <MultiSelectV3
        className="job-index-department-filter"
        mode="multiple"
        size="small"
        maxLength={100}
        maxDropdownPills={1}
        showTags={true}
        showPills={false}
        defaultSelection={defaultValues.departments}
        placeholder={Labels('DEPARTMENT')}
        data={defaultOptions?.departments || []}
        onSelectionChange={departments => {
          onChange?.({
            ...selectedValues,
            departments: map(departments, department => department.value)
          })
        }}
      />
      {/* END OF DEPARTMENT FILTER */}
      {/* LOCATION FILTER */}
      <ShouldRender check={showLabel}>
        <LabelComponent label={Labels('LOCATION')} />
      </ShouldRender>
      <MultiSelectV3
        showSearch
        allowReset
        maxLength={100}
        showPills={false}
        defaultSelection={defaultValues.location}
        placeholder={Labels('LOCATION')}
        data={defaultOptions?.locations}
        onSelectionChange={data => {
          onChange?.({ ...selectedValues, location: data[0]?.value })
        }}
      />
      {/* END OF LOCATION FILTER */}
      {/* DATE POSTED FILTER */}
      <ShouldRender check={showLabel}>
        <LabelComponent label={Labels('DATE_POSTED')} />
      </ShouldRender>
      <div className="w-full lg:w-1/2">
        <MultiSelectV3
          allowReset
          maxLength={100}
          showPills={false}
          defaultSelection={defaultValues.datePosted}
          placeholder={Labels('DATE_POSTED')}
          data={defaultOptions?.datePosted || []}
          onSelectionChange={data => {
            onChange?.({ ...selectedValues, datePosted: data[0]?.value })
          }}
        />
      </div>
      {/* END OF DATE POSTED FILTER */}
      {/* EASY APPLY FILTER */}
      <ShouldRender check={!hideFilters?.includes(FilterEnum.EASY_APPLY)}>
        <AntdButton
          className={clsx(
            'rounded-md hover:bg-qureosPrimary hover:border-qureosPrimary hover:text-white mt-1.5',
            defaultValues.easyApply
              ? 'bg-qureosPrimary border-qureosPrimary text-white'
              : 'text-black border-gray-300'
          )}
          onClick={() => {
            onChange?.({
              ...selectedValues,
              easyApply: !defaultValues.easyApply
            })
          }}
        >
          {Labels('EASY_APPLY')}
        </AntdButton>
      </ShouldRender>
      {/* EASY APPLY FILTER */}
      {/* CLEAR FILTERS */}
      <ShouldRender
        check={
          !isEmpty(selectedValues.datePosted) ||
          !isEmpty(selectedValues.departments) ||
          !isEmpty(selectedValues.easyApply) ||
          !isEmpty(selectedValues.jobTitle) ||
          !isEmpty(selectedValues.location)
        }
      >
        <button
          tabIndex={0}
          className=" hover:text-qureosPrimary whitespace-pre mt-1 text-center"
          onClick={() => {
            onChange?.({
              departments: undefined,
              location: undefined,
              datePosted: undefined,
              easyApply: false,
              jobTitle: undefined
            })
          }}
        >
          {Labels('CLEAR_FILTERS')}
        </button>
      </ShouldRender>
      {/* END OF CLEAR FILTERS */}
    </Fragment>
  )

  return (
    <Fragment>
      <div className="w-full flex justify-between gap-2">
        {/* JOB TITLE FILTER */}
        <MultiSelectV3
          showSearch
          allowReset
          maxLength={100}
          showPills={false}
          className="max-w-[380px]"
          defaultSelection={defaultValues.jobTitle}
          placeholder={Labels('JOB_TITLE')}
          data={
            !isUndefined(jobTitleOptions)
              ? jobTitleOptions
              : defaultOptions?.jobTitles
          }
          onSelectionChange={data => {
            onChange?.({
              ...selectedValues,
              jobTitle: first(data)?.value
            })
          }}
          handleSearchQuery={handleJobTitleSearch}
          loading={loadingSearch}
        />
        {/* JOB TITLE FILTER */}
        <div className="w-2/3 hidden lg:flex">
          <div className="w-full flex gap-2 items-center">{otherFilters()}</div>
        </div>
        <div className="w-max flex lg:hidden">
          <div className="flex gap-2 items-center">
            <AntdButton
              className="rounded-md mt-2 text-black border-gray-300 flex gap-2"
              onClick={() => setIsModalVisible(true)}
            >
              <AdjustmentsVerticalIcon className="text-gray-500 w-5" />{' '}
              {Labels('FILTERS')}
            </AntdButton>
          </div>
        </div>
      </div>
      <Modal
        overlayClass="bg-darkKnight bg-opacity-80"
        onClose={() => setIsModalVisible(false)}
        setOpen={() => setIsModalVisible(false)}
        showCloseIcon={true}
        open={isModalVisible}
      >
        <div className="p-8">
          <div className="w-full">
            <div className="flex flex-col gap-2 items-center">
              {otherFilters(true)}
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default JobFilterOrganism
