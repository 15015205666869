import React, { Fragment, ReactNode, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ShouldRender } from '@/components/shared/misc'
import clsx from 'clsx'

export interface ModalProps {
  open: boolean
  setOpen?: (flag: boolean) => void
  onClose?: () => void
  showCloseIcon?: boolean
  className?: string
  overlayClass?: string
  /**
   * Sets a max width of max-w-xl by default. Set it to false if you want your modal to have width greater than that
   *  */
  restrainWidth?: boolean
  dialogClass?: string
  zeroPadding?: boolean
  children?: ReactNode
}

/* new model using tailwinddUI */
const Modal: React.FC<ModalProps> = ({
  open,
  setOpen,
  onClose,
  showCloseIcon = false,
  children,
  className,
  overlayClass = 'bg-gray-700',
  restrainWidth = true,
  dialogClass,
  zeroPadding = false
}) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed z-[1000] inset-0 overflow-y-auto', dialogClass)}
        initialFocus={cancelButtonRef}
        onClose={onClose ?? setOpen}
      >
        <div
          className={clsx(
            'modal flex items-center sm:items-end justify-center min-h-screen text-center sm:block',
            !zeroPadding && 'px-4 py-10'
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={clsx('fixed inset-0 transition-opacity', overlayClass)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                { 'max-w-xl': restrainWidth },
                'inline-block sm:align-middle align-bottom',
                className
              )}
            >
              <ShouldRender check={showCloseIcon}>
                <div className="grid justify-items-end ">
                  <XMarkIcon
                    onClick={() => setOpen(false)}
                    className="h-6 w-6 text-white z-[1000]"
                    aria-hidden="true"
                  />
                </div>
              </ShouldRender>
              <div className="bg-white rounded-lg shadow-xl transform transition-all w-fit">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
