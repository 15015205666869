import {
  Context,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState
} from 'react'

export const LearningHubTabContext: Context<{
  setActiveTab: Dispatch<SetStateAction<string>>
  activeTab: string
}> = createContext(undefined)

export interface LearningHubTabProviderProps {
  tabsKeys: string[]
  children?: ReactNode
}

export const LearningHubTabProvider: React.FC<LearningHubTabProviderProps> = ({
  children,
  tabsKeys
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabsKeys[0])
  return (
    <LearningHubTabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </LearningHubTabContext.Provider>
  )
}
