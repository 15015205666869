import clsx from 'clsx'
import { isEmptyArray } from 'formik'
import Cookies from 'js-cookie'
import {
  assign,
  castArray,
  compact,
  filter,
  find,
  identity,
  isEmpty,
  isNumber,
  isUndefined,
  map,
  pickBy,
  set,
  toLower
} from 'lodash'
import { NextPage, NextPageContext } from 'next'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { jobsMeta as jobsMetaAtom, user as userAtom } from 'src/atoms'
import useJobFilters from 'src/hooks/data/useJobFilters'
import { getAllJobs, getJobsMeta } from 'src/services/jobs'
import { SeoMetaType } from 'src/types/seo'
import { User, UserRole } from 'src/types/shared/graphql'

import AppliedJobs from '@/components/jobs/AppliedJobs'
import PublicJobs from '@/components/jobs/PublicJobs'
import TabNavigation from '@/components/learning-hub/TabNavigation'
import { ShouldRender } from '@/components/shared/misc'
import JobFilterOrganism from '@/components/shared/SortAndFilterV2/filters/JobFiters/JobFilterOrganism'
import { MainTemplate } from '@/components/shared/templates'
import DashboardTemplateV2 from '@/components/shared/templates/DashboardTemplateV2'
import { LearningHubTabProvider } from '@/providers/learning-hub/LearningHubTabProvider'
import { isLoggedIn } from '@/utils/lib'
import getTranslations from '@/utils/lib/get-translations'
import { getJobSeoMeta } from '@/utils/seo/job'
import { fetchMetaScope } from '@/utils/seo/metadata-helper'
import { CorporateJobResponse, JobsMeta } from '@qureos/types'

export interface JobsPageProps {
  company: string | string[]
  id: string | string[]
  location: string | string[]
  theme: string | string[]
  typeOfWork: string | string[]
  title?: string
  filterOptions: JobsMeta
  seoMeta: SeoMetaType
  hideFilters?: boolean
  useNoIndexFollow?: boolean
  currentTab?: string
}

const JobsIndex: NextPage<JobsPageProps> = ({
  filterOptions,
  seoMeta,
  hideFilters = false,
  useNoIndexFollow = false,
  currentTab = 'all-jobs'
}) => {
  const router = useRouter()
  const user = useRecoilValue<User>(userAtom)
  const {
    jobId,
    pageNumber,
    company: companyId,
    currentTab: tabValue
  } = router.query
  const pathName = router.pathname
  const [companyMetaData, setCompanyMetaData] = useState<SeoMetaType>()
  const [, setJobsMeta] = useRecoilState<JobsMeta>(jobsMetaAtom)
  const departmentFilter = Cookies.get('departmentFilter')
  const {
    datePostedFilters,
    themes,
    locations,
    jobTitle,
    defaultJobTitles,
    selectedLocation,
    selectedThemes,
    selectedDatePosted,
    selectedEasyApply,
    handleFilterChange,
    filters
  } = useJobFilters({
    themes: filterOptions?.themes ?? [],
    locations: filterOptions?.locations ?? []
  })
  const { t: Labels } = useTranslation('CorporateJobs')

  const [selectedJobId, setSelectedJobId] = useState<string>(String(jobId))
  const [selectedJob, setSelectedJob] = useState<CorporateJobResponse>(null)
  const [isDataReady, setIsDataReady] = useState<boolean>(false)

  useMemo(() => {
    const meta = {
      selectedTheme: map(selectedThemes, item => item?._id),
      allThemes: themes
    }

    if (jobTitle !== 'undefined' && !isUndefined(jobTitle)) {
      set(meta, 'searchQuery', jobTitle)
    }

    if (selectedLocation !== 'undefined' && !isUndefined(selectedLocation)) {
      set(meta, 'selectedLocation', selectedLocation)
    }

    if (
      !isEmpty(selectedJob?.company?.name) &&
      toLower(selectedJob?.company?.name) !== 'confidential'
    ) {
      set(meta, 'companyName', selectedJob?.company?.name)
    }

    if (selectedJob?.locations?.length === 1) {
      set(meta, 'companyLocation', selectedJob.locations?.[0]?.location)
    }

    if (!selectedJob?.locations?.length && !isEmpty(selectedJob?.location)) {
      set(meta, 'companyLocation', selectedJob.location)
    }

    const updatedCanonical = getJobSeoMeta(meta)
    assign(seoMeta, updatedCanonical)
  }, [selectedLocation, jobTitle, selectedThemes, selectedJob])

  // this one should not be automated
  useEffect(() => {
    if (departmentFilter && isEmptyArray(JSON.parse(departmentFilter))) {
      Cookies.remove('departmentFilter')
    } else if (departmentFilter) {
      const themeIds = JSON.parse(departmentFilter)?.map(theme => theme._id)
      const queryParams = themeIds.map(id => `theme=${encodeURIComponent(id)}`)
      const queryString = queryParams.join('&')
      router.push(`${router.pathname}?${queryString}`)
    }
  }, [departmentFilter])

  const handleJobIdChange = (jobId: string) => {
    if (selectedJobId === jobId) return
    setSelectedJobId(jobId)
    router.replace(
      {
        pathname: pathName,
        query: {
          ...router.query,
          jobId
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const handlePageNumberChange = (newPageNumber: number) => {
    if (Number(pageNumber) === newPageNumber) return
    router.replace(
      {
        pathname: pathName,
        query: {
          ...router.query,
          pageNumber: newPageNumber
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  useEffect(() => {
    if (typeof pageNumber === 'undefined') {
      handlePageNumberChange(1)
    }
  }, [pageNumber])

  useEffect(() => {
    fetchMetaScope(companyId, setCompanyMetaData)
  }, [companyId])

  useEffect(() => {
    if (isLoggedIn() && user && user.role !== UserRole.Student) {
      router.push('/home')
    } else {
      setJobsMeta(filterOptions)
      setIsDataReady(true)
    }
  }, [user, filterOptions])

  const renderedFilters = (
    <div className="w-full flex gap-2 h-10 mx-auto justify-center">
      <ShouldRender check={!hideFilters && isDataReady}>
        <JobFilterOrganism
          defaultValues={{
            location:
              selectedLocation === 'undefined' || isUndefined(selectedLocation)
                ? []
                : [
                    {
                      label: selectedLocation,
                      value: selectedLocation
                    }
                  ],
            departments: map(
              filter(selectedThemes, theme => !isUndefined(theme)),
              theme => ({
                label: theme?.label,
                value: theme?._id
              })
            ),
            datePosted:
              !isEmpty(selectedDatePosted) || !isUndefined(selectedDatePosted)
                ? compact([
                    find(datePostedFilters, {
                      value: selectedDatePosted
                    })
                  ])
                : [],
            easyApply: selectedEasyApply,
            jobTitle:
              jobTitle === 'undefined' || isUndefined(jobTitle)
                ? []
                : [
                    {
                      label: jobTitle,
                      value: jobTitle
                    }
                  ]
          }}
          defaultOptions={{
            datePosted: datePostedFilters,
            departments: map(themes, theme => ({
              label: theme.label,
              value: theme._id
            })),
            locations: map(locations, location => ({
              label: location,
              value: location
            })),
            jobTitles: map(defaultJobTitles, jobTitle => ({
              label: jobTitle,
              value: jobTitle
            }))
          }}
          onChange={handleFilterChange}
        />
      </ShouldRender>
      <ShouldRender check={hideFilters}>
        <Link href="/jobs">
          <a className="border border-gray-300 rounded-md text-center flex items-center px-3 font-medium text-md text-gray-600">
            {Labels('CLEAR_FILTER')}
          </a>
        </Link>
      </ShouldRender>
    </div>
  )

  const jobData = (isPublic: boolean) => {
    const tabs = [
      {
        title: Labels('AVAILABLE_JOBS'),
        key: 'all-jobs',
        children: (
          <PublicJobs
            jobId={selectedJobId}
            pageNumber={Number(pageNumber) || 1}
            handlePageNumberChange={handlePageNumberChange}
            handleJobIdChange={handleJobIdChange}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            filters={filters}
          />
        )
      }
    ]
    if (!isPublic) {
      tabs.push({
        title: Labels('APPLIED_JOBS'),
        key: 'applied-jobs',
        children: <AppliedJobs />
      })
    }
    return (
      <div
        className={clsx('md:mx-4 mx-auto flex-1 overflow-hidden max-w-[90%]', {
          'mt-5': !isLoggedIn()
        })}
      >
        <qc-should-render check={tabValue !== 'applied-jobs'}>
          <h1 className="mb-3 text-base font-normal">
            {seoMeta.heading.length > 0 ? seoMeta.heading : 'Jobs'}
          </h1>
          {renderedFilters}
        </qc-should-render>
        <div
          className={clsx(
            'bg-white mt-5 mx-auto flex-1 rounded-lg shadow overflow-hidden'
          )}
        >
          <LearningHubTabProvider tabsKeys={['all-jobs']}>
            <TabNavigation
              className={isPublic ? 'hide-mobile-tabs' : ''}
              tabs={tabs}
              isPreserve={true}
              showTabs={isLoggedIn()}
            />
          </LearningHubTabProvider>
        </div>
      </div>
    )
  }

  const seoTitle =
      seoMeta?.title || companyMetaData?.title || Labels('ALL_JOBS'),
    seoDescription = seoMeta?.description || companyMetaData?.description

  if (!isLoggedIn()) {
    return (
      <MainTemplate
        title={seoTitle}
        meta={seoDescription}
        useNoIndexFollow={useNoIndexFollow}
        metaCanonical={seoMeta?.canonical}
        seoOgMeta={seoMeta?.ogMeta}
      >
        {jobData(true)}
      </MainTemplate>
    )
  }

  return (
    <DashboardTemplateV2
      title={seoTitle}
      meta={seoDescription}
      metaCanonical={seoMeta?.canonical}
      seoOgMeta={seoMeta?.ogMeta}
      showBreadCrumbs
      useNoIndexFollow={useNoIndexFollow}
      className="flex flex-1 bg-qureosBgGray justify-center"
    >
      {jobData(false)}
    </DashboardTemplateV2>
  )
}

// function to get jobs using serverSide and return the count
// purpose is to pass the count and determine if noIndex is needed or not

const getJobsCount = async (context: NextPageContext) => {
  const { query } = context

  const perPageJobsCount = 9
  const actualCurrentPageNumber = Number(query.pageNumber) || 1
  const pageNumberOffset = actualCurrentPageNumber - 1
  const offset = perPageJobsCount * pageNumberOffset

  const filterQuery = {
    ...pickBy(
      {
        themeIds: !isUndefined(query.theme)
          ? castArray(query.theme)
          : undefined,
        location: query.location
          ? decodeURI(query.location as string)
          : undefined,
        'createdAt[gte]': query.datePosted ? query.datePosted : undefined,
        includePublicJobs: !query.easyApply,
        title: query.title ? query.title : undefined
      },
      identity
    )
  }
  const jobs = await getAllJobs({
    ignoreAppliedJobs: false,
    limit: perPageJobsCount,
    offset: isNumber(offset) ? offset : 0,
    ...filterQuery
  })

  return jobs?.metaData?.[0]?.total || 0
}

export async function getServerSideProps(context: NextPageContext) {
  const [modifiedProps, filterOptions] = await Promise.all([
    getTranslations(context),
    getJobsMeta({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      bearerToken: context.req.cookies.auth_token
    })
  ])
  const { translations, query } = modifiedProps

  const { company, id, location, theme, typeOfWork, title, currentTab, jobId } =
    query || {}
  const seoMeta = getJobSeoMeta({
    searchQuery: query.query as string,
    selectedLocation: location as string,
    selectedTheme: theme,
    allThemes: filterOptions?.themes,
    jobId: jobId !== 'undefined' && jobId?.length ? (jobId as string) : ''
  })

  const totalJobs = await getJobsCount(context)

  // we only do noIndex if count of jobs are less than 5
  const useNoIndexFollow = totalJobs < 5
  return {
    props: {
      id: id ?? null,
      title: (title as string) ?? null,
      currentTab: currentTab ?? 'all-jobs',
      filterOptions,
      seoMeta,
      ...pickBy(
        {
          theme: theme ? castArray(theme) : undefined,
          location: location ? decodeURI(location as string) : undefined,
          typeOfWork: typeOfWork ? castArray(typeOfWork) : undefined,
          company: company ? castArray(company) : undefined,
          title: title as string
        },
        identity
      ),
      ...translations,
      useNoIndexFollow: useNoIndexFollow
    }
  }
}

export default JobsIndex
