import Image from '@/components/shared/misc/Photo/Image'
import {
  ArrowTopRightOnSquareIcon,
  MapPinIcon
} from '@heroicons/react/24/solid'
import { officeBuilding } from '@qureos/assets'
import clsx from 'clsx'
import moment from 'moment'
import { useMemo } from 'react'
import { JobContractType, JobResponse, JobSite } from 'src/types/jobs'
import { ShouldRender } from '../shared/misc'

export const JobCard: React.FC<{
  job: JobResponse
  width?: string
  showCompanyTag?: boolean
  applicationStatus?: any
}> = ({ job, width, showCompanyTag = true, applicationStatus }) => {
  const {
    company: { logo, name, businessName },
    contractType,
    createdDateTime,
    location,
    site,
    title,
    status,
    workStyles,
    locations
  } = job

  const dateCapped = useMemo(() => {
    const monthAgo = moment().subtract(1, 'month')
    return moment.max(monthAgo, moment(createdDateTime))
  }, [createdDateTime])

  return (
    <div
      className={clsx(
        'shadow-xl rounded-lg p-4 flex flex-col justify-between hover:cursor-pointer  min-h-[185px] h-full bg-white relative',
        width ?? 'w-full md:max-w-[340px]'
      )}
    >
      <ShouldRender check={businessName && showCompanyTag}>
        <div className="absolute md:static lg:absolute right-4">
          <div className=" bg-[#DBEAFE] px-2 py-1 text-[#1E40AF] text-xs text-center rounded font-medium uppercase">
            {businessName}
          </div>
        </div>
      </ShouldRender>

      <div className="flex-1 md:flex">
        <Image
          alt={name}
          src={logo}
          fallbackSrc={officeBuilding}
          wrapperClass="w-10 xl:w-16 h-10 xl:w-16 rounded-3xl mt-2 mr-4 flex items-center justify-center"
        />
        <div className="md:flex-1 w-[calc(100%-56px)] xl:w-[calc(100%-75px)] mb-2">
          <div className="overflow-hidden leading-6 text-gray-900 text-ellipsis md:w-40 capitalize">
            {name}
          </div>
          <div className="overflow-hidden text-base font-semibold leading-6 text-gray-900 text-ellipsis capitalize">
            {title}
          </div>
          <ShouldRender
            check={locations?.length || workStyles?.length || location?.length}
          >
            <div className="flex items-start mt-2 text-sm font-normal leading-5 text-gray-500 justify-start gap-1">
              <MapPinIcon className="flex-shrink-0 h-5 w-5 text-gray-500" />
              <div className="flex flex-wrap items-start justify-start">
                <ShouldRender check={workStyles?.length}>
                  <p className="text-sm leading-5 text-gray-500">
                    {workStyles && JobSite[workStyles[0]]}
                    <ShouldRender
                      check={locations?.length && workStyles?.length}
                    >
                      <span className="pr-1"> | </span>
                    </ShouldRender>
                  </p>
                </ShouldRender>
                <ShouldRender check={locations?.length}>
                  <div className="flex items-start justify-center gap-1">
                    <span className="break-words max-w-sm">
                      {locations
                        ?.slice(0, 1)
                        .map(i => i.location)
                        .join(',') || ''}
                    </span>
                    <ShouldRender check={locations?.length > 1}>
                      <span className="bg-gray-100 rounded-full w-auto px-1 ">
                        +{locations?.length - 1}
                      </span>
                    </ShouldRender>
                  </div>
                </ShouldRender>
                <ShouldRender check={!locations && location?.length}>
                  <div className="flex items-start justify-center gap-1">
                    <span className="break-words max-w-sm">{location}</span>
                  </div>
                </ShouldRender>
              </div>
            </div>
          </ShouldRender>
        </div>
      </div>
      <hr className="py-2" />
      <div className="flex items-center justify-between text-xs">
        <ShouldRender check={job.isExternal}>
          <p className="px-3 py-1 font-semibold capitalize rounded">
            <ArrowTopRightOnSquareIcon className="h-5 w-5 text-[#9CA3AF]" />
          </p>
        </ShouldRender>
        <ShouldRender check={!job.isExternal && contractType}>
          <p className="px-3 py-1 font-semibold text-gray-500 capitalize bg-gray-100 rounded">
            {JobContractType[contractType]
              ? JobContractType[contractType]
              : contractType?.toLowerCase()}
          </p>
        </ShouldRender>
        <ShouldRender check={status === 'CLOSED'}>
          <p className="bg-status-error bg-opacity-10 text-status-error rounded text-sm py-1 px-3">
            Closed
          </p>
        </ShouldRender>
        <ShouldRender check={status !== 'CLOSED' && createdDateTime}>
          <p className="font-bold text-right text-gray-400 whitespace-nowrap">
            {dateCapped.fromNow()}
          </p>
        </ShouldRender>
        <ShouldRender check={applicationStatus}>
          {applicationStatus}
        </ShouldRender>
      </div>
    </div>
  )
}
